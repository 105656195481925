import * as React from "react";
import "../sass/alertBox.scss";

interface IProps {
    alert: {
        title: string;
        body: string;
        color: string;
        afterClose: Function;
    },
    closeAlert: Function
}

// Don't judge me. Robione na szybko przez nieoczekiwane zmiany założeń całej apki.
// TODO: Obsługa błędów do szybkiego refactoru.

export const AlertBox: React.FC<IProps> = props => {

    // React.useEffect(() => {
    //     setTimeout(() => {
    //         props.closeAlert();
    //         try {
    //             props.alert.afterClose()
    //         } catch (e) {
    //         }
    //     }, 3000)
    // });

    return <div className="alert-box">
        <div className="alert-box__window" style={{backgroundColor: props.alert.color}}>
            <div className="alert-box__window--title">
                {props.alert.title}
            </div>
            <div className="alert-box__window--body">
                {props.alert.body}
            </div>
            <div className="btn alert-box__close-btn" onClick={() => {
                props.closeAlert();
                try {
                    props.alert.afterClose()
                } catch (e) {
                }
            }}>
                Zamknij
            </div>
        </div>
    </div>;

};
