export const DictionaryPL: any = {
    projectName: 'Nazwa projektu',
    email: 'e-mail',
    companyName: 'Nazwa firmy',
    licenseLength: 'Długość licencji',
    mediaType: 'Pola eksploatacji',
    territory: 'Terytorium',
    terytory: 'Terytorium',
    trackName: 'Nazwa utworu',
    promoCode: 'Kod promocyjny',
    creationDate: 'Data utworzenia',
    startDate: 'Data startu licencji',
    linkToValuation: 'Link do wyceny',
    offerPrice: 'Cena netto',
    musicDatabase: 'Katalog',
    PL: 'Polska',
    DE: 'Niemcy',
    US: 'USA'
}

export const httpColors: any = {
    200: '#4CAF50',
    201: '#4CAF50',
    400: '#f44336',
    404: '#f44336',
    500: '#ff9800'
}
