import * as React from "react";
import {FormValues} from "../types/formTypes";
import {ErrorMessage, Field, FormikErrors} from "formik";
import {stepEnum} from "../enum/global";
import {appURL, baseURL} from "../index";
import {DictionaryPL, httpColors} from "./dictionary";
import moment from "moment";

const inputNameDictionary: any = {
    // : FormValues
    companyName: "Nazwa firmy",
    email: "Email",
    project: "Projekt"
};

export function createCamelSlug(str: string) {
    return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
        if (+match === 0) return "";
        return index === 0 ? match.toLowerCase() : match.toUpperCase();
    });
}

export function formTextInputsGenerator(inputs: FormValues, step: stepEnum) {
    return Object.entries(inputs).map(([key, initValue]) => (
        <div className="main-form__single-input-wrapper" key={key}>
            <label
                htmlFor={`${key}Id`}
                className="main-form__single-input main-form__single-input--label"
            >
                {inputNameDictionary[key]}
            </label>
            <Field
                type="text"
                id={`${key}Id`}
                name={key}
                className="main-form__single-input "
            />
            <ErrorMessage name={key} component="div" className="errorMessage"/>
        </div>
    ));
}

export function inputTextField(name: string, step: stepEnum) {

    return (
        <Field
            type="text"
            id={`${name}Id`}
            name={name}
            className="main-form__single-input"
            disabled={step === stepEnum.SUMMARY}
        />
    );
}

export function formValidation(values: any, songIncluded: boolean) {
    const errors: FormikErrors<any> = {};
    if (songIncluded && typeof values.songList !== 'undefined' && values.songList.length > 0) {
        let tmpErr: Array<any> = [];
        let countErr = 0;
        values.songList.forEach((singleSong: any) => {
            let tmpRow: any = {};
            ["title", /*"album",*/ "performer"].forEach(name => {
                if (!singleSong[name]) {
                    tmpRow[name] = "Wymagane";
                    countErr++;
                } else if (singleSong[name].length < 4) {
                    tmpRow[name] = "Conajmniej 4 znaki";
                    countErr++;
                }
            });
            tmpErr.push(tmpRow);
        });

        if (countErr > 0) {
            errors.songList = tmpErr;
        }
    }

    if (typeof values.videoType === 'undefined' || values.videoType.length <= 0) {
        errors.videoType = "Wybierz conajmniej jeden rodzaj filmu";
    }

    if (!values.email) {
        errors.email = "Wymagane";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
        errors.email = "Invalid email address";
    }

    ["projectName", "companyName"].forEach(item => {
        if (!values[item]) {
            errors[item] = "Wymagane";
        } else if (values[item].length < 4) {
            errors[item] = "Conajmniej 4 znaki";
        }
    });

    ['mediaType', 'territory'].forEach(item => {
        if (typeof values[item] === 'undefined' || values[item] === null || values[item].length <= 0) {
            errors[item] = "Wybierz conajmniej jedno pole";
        }
    });

    if (typeof values.licenseLength === 'undefined' || values.licenseLength === null) {
        errors.licenseLength = "Wybierz czas trwania licencji";
    }

    return errors;
}

export function generateValues(values: any = {}): FormValues {
    const {projectName, email, companyName, licenseLength, mediaType, terytory, trackName, promoCode, startDate} = values;

    return {
        videoType: ['type-advertisement'],
        projectName: projectName || "",
        email: email || "",
        companyName: companyName || "",
        licenseLength: licenseLength || null,
        mediaType: mediaType || [],
        territory: terytory || ['PL'],
        startDate: startDate || "",
        songList: trackName || [
            {
                title: "",
                // album: "",
                performer: "",
                musicDatabase: "Right Publishing"
            }
        ],
        promoCode: promoCode || null
    };
}

export function mapToSummary(values: any = {}) {
    const {projectName, email, companyName, licenseLength, terytory, trackName, promoCode, creationDate, startDate, musicDatabase} = values.licenses[0];
    let mediaTypes: any = [];
    values.licenses.forEach((license: any) => {
        mediaTypes.push(license.mediaType);
    });

    return {
        projectName: projectName || "",
        email: email || "",
        companyName: companyName || "",
        licenseLength: `${licenseLength} mc` || "",
        mediaType: mediaTypes.join(', ') || "",
        territory: terytory || "",
        trackName: trackName || "",
        musicDatabase: musicDatabase || "",
        promoCode: promoCode || "brak",
        creationDate: moment(creationDate).format('YYYY-MM-DD') || "",
        startDate: moment(startDate).isValid() ? moment(startDate).format('YYYY-MM-DD') : "",
        offerPrice: `${values.licensePrice},-` || ""
    };
}

export function mapToLinks(values: any = {}) {
    const {projectName, trackName, hash, terytory, musicDatabase, mediaTypes} = values;
    // const mediaType = values.mediaTypes.toString();
    return {
        projectName: projectName || "",
        trackName: trackName || "-",
        musicDatabase: musicDatabase || "-",
        mediaType: mediaTypes || "",
        territory: DictionaryPL[terytory] || terytory || "",
        // offerPrice: `${offerPrice},-` || "",
        linkToValuation: `${appURL}?hash=${hash}`
    };
}

export async function postData(url = '', data = {}) {
    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        referrerPolicy: 'no-referrer',
        body: JSON.stringify(data)
    });

    switch (response.status) {
        case 200: {
            const res = await response.json();
            return Object.assign(res, {
                color: httpColors['200'],
                title: "Utworzono",
                body: "Wyceny Twoich licencji zostały wysłane na podany adres. Zobaczysz je też w tabelce poniżej."
            });
        }
        case 201: {
            const res = await response.json();
            return Object.assign(res, {
                color: httpColors['201'],
                title: "Utworzono",
                body: "Wyceny Twoich licencji zostały wysłane na podany adres. Zobaczysz je też w tabelce poniżej."
            });
        }
        case 400: {
            const res = await response.text();
            throw {color: httpColors['400'], title: "Błąd", body: res}
        }
        case 404:
        case 405: {
            throw {color: httpColors['404'], title: "Błąd", body: "Wystąpił błąd komunikacji z serwerem."}
        }
        default: {
            return {color: httpColors['404'], title: "Błąd", body: "Wystąpił błąd komunikacji z serwerem."};
        }
    }
}

export async function buyLicense(evaluationHash: string) {
    try {
        return await postData(`${baseURL}/buy`, {hash: evaluationHash});
    } catch (err) {
        console.log('buy error', err);
        return false;
    }
};

export async function editLicense(url: string, data = {}) {
    try {
        // await postData(baseURL, data);
        return await postData(baseURL, data);
    } catch (err) {
        console.log('edit error', err);
        return false;
    }
}

export function validateEdit(values: any) {
    const errors: FormikErrors<any> = {};
    const songError: any = {};
    let hasError = false;

    if (typeof values.songList !== 'undefined' && values.songList.length > 0) {
        ['title', 'performer'].forEach(name => {
            if (!values.songList[0][name]) {
                hasError = true;
                songError[name] = "Wymagane";
            } else if (values.songList[0][name].length < 4) {
                hasError = true;
                songError[name] = "Conajmniej 4 znaki";
            }
        });
    }

    if (hasError) {
        errors.songList = [songError];
    }
    if (values.licenses[0].startDate === null && values.startDate === null) {
        errors.startDate = 'Ustaw datę startu licencji';
    }

    return errors;
}
