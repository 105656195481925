import React, {useState} from "react";
import "./sass/general.scss";
import {MainForm} from "./components/MainForm";
import {viewModeEnum} from "./enum/global";
import {appURL} from "./index";
import {AlertBox} from "./components/AlertBox";

export const App: React.FC = () => {
    const formRef = React.useRef(null);
    const [promoCode, setPromoCode] = useState(null);
    const [viewMode, setViewMode] = useState(viewModeEnum.DEFAULT);
    const [isLoading, setIsLoading] = useState(false);
    const [alert, setAlert] = useState(null);

    return (
        <div className="app-container">
            {alert && <AlertBox alert={alert} closeAlert={() => setAlert(null)}/>}
            <div className="app-container__left-col">
                <div className="text text__header text--dark">
                    Zakup licencji
                </div>

                <div className="text mt-20 mb-10">
                    <div className="bold">
                        {/*{viewMode === viewModeEnum.FORM_VIEW &&*/}
                        {/*<span> Wybierz rodzaj filmów, do których będziesz używać wybranych utworów </span>}*/}
                        {viewMode === viewModeEnum.SUMMARY_VIEW &&
                        <span> Oto wyceny Twoich licencji (zostały przesłane również e-mailem): </span>}
                    </div>
                </div>

                <MainForm ref={formRef}
                          promoCode={promoCode}
                          changeView={(mode: viewModeEnum) => setViewMode(mode)}
                          changeIsLoading={(val: boolean) => setIsLoading(val)}
                          showAlertBox={setAlert}/>

                <div className="catalogs-links">
                    <div>
                        Dostępne katalogi:
                    </div>
                    <ul>
                        <li className="link">
                            <a href="https://system.rightpublishing.com/login" target="_blank" rel="noopener noreferrer">Right Publishing</a>
                        </li>
                        <li className="link">
                            <a href="https://www.epidemicsound.com/" target="_blank" rel="noopener noreferrer">Right Music</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="app-container__right-col flex flex--column-align-bottom">
                {viewMode === viewModeEnum.FORM_VIEW &&
                <>
                    <div className="flex flex--column">
                        <div className="text text--primary">
                            <div>
                                Masz kod rabatowy?
                            </div>
                            <div>
                                Wpisz go poniżej.
                            </div>
                        </div>

                        <div className="text-input text-input--big mt-30">
                            <div>
                                <input type="text" id="code-input" onChange={event => {
                                    let element = event.currentTarget as HTMLInputElement;
                                    setPromoCode(element.value);
                                    // console.log(element.value, promoCode);
                                }}/>
                            </div>
                            <label htmlFor="code-input" className="text-input__label">
                                Kod rabatowy
                            </label>
                        </div>
                    </div>

                    <div className="flex submit-wrapper">
                        <div className="text text--primary submit-wrapper__info-box">
                            Wycena zostanie wysłana na podany e-mail
                        </div>
                        <button className="btn" type="button" onClick={() => {
                            formRef.current?.submitForm();
                        }} disabled={isLoading}>
                            Wyceń {isLoading && <img src='./spinner.gif' className='spinner' alt='&compfn;'/>}
                        </button>
                    </div>
                </>
                }
                {viewMode === viewModeEnum.SUMMARY_VIEW &&
                <>
                    <div className="flex flex--column">
                        <div className="text text__header text--primary">
                            Wróć do formularza
                        </div>
                        <div className="flex submit-wrapper">
                            {/*<a className="btn" href="http://localhost:3000">*/}
                            {/*    Wróć*/}
                            {/*</a>*/}
                            <a className="btn" href={appURL}>
                                Wróć
                            </a>
                        </div>
                    </div>
                </>
                }
            </div>
        </div>
    );
};

export default App;
