export enum stepEnum {
    MODE_SELECT,
    MAIN_FORM,
    SUMMARY
}

export enum modeEnum {
    NOT_SELECTED,
    CORPORATE_MOVIES,
    ADVERTISING_MOVIES
}

export enum mdbTypeEnum {
    NOT_SELECTED,
    RIGHT_PUBLISHING,
    RIGHT_MUSIC
}

export enum viewModeEnum {
    FORM_VIEW,
    SUMMARY_VIEW,
    LIST_VIEW,
    DEFAULT
}
