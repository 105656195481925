import * as React from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import {SelectOption} from "../types/formTypes";
import {FormikErrors} from "formik";

const animatedComponents = makeAnimated();

const selectStyles = {
    control: (styles: any) => ({
        ...styles,
        backgroundColor: "#FFF",
        borderWidth: 0,
        borderBottom: "1px solid #000",
        borderRadius: 0,
        boxShadow: "none",
        '&:hover': {
            boxShadow: "none",
            cursor: "pointer"
        },
        '&:focus': {
            boxShadow: "none",
        }
    }),
    // multiValueRemove: (styles: any) => ({
    //     ...styles,
    //     width: '35px'
    // }),
};

interface IProps {
    title?: string,
    setField: Function,
    name: string,
    options: Array<SelectOption>,
    errors?: FormikErrors<any>,
    touched: any,
    multi?: boolean,
    closeMenu?: boolean,
    titleBottom?: boolean,
    addClass?: string,
    defaultOption?: SelectOption
}

export const CustomSelect: React.FC<IProps> = props => {

    const {errors} = props;

    function handleChange(values: any) {
        let selected = null;

        try {
            if (!Array.isArray(values)) {
                selected = values.value;
            } else {
                selected = values !== null ? values.reduce((prev: any, next: any) => {
                    return prev.concat(next.value);
                }, []) : [];
            }
        } catch(e) {
            selected = null;
        }

        // console.log(props.name, selected);

        props.setField(props.name, selected);
        props.touched[props.name] = true;
    }

    return (
        <div className={"select-wrapper text text--dark half-col--mr " + props.addClass}>
            {!props.titleBottom && <div className="select-wrapper__label">
                <div className="bold">{props.title}</div>
            </div>}
            <Select
                closeMenuOnSelect={props.closeMenu}
                components={animatedComponents}
                isMulti={props.multi}
                options={props.options}
                styles={selectStyles}
                onChange={handleChange}
                placeholder={false}
                className={'react-select-container'}
                classNamePrefix={'react-select'}
                defaultValue={props.defaultOption}
            />
            {props.titleBottom && <div className="text-input__label">{props.title}</div>}

            {(typeof props.touched[props.name] === 'undefined' || props.touched[props.name]) && typeof errors !== 'undefined' && typeof errors[props.name] !== 'undefined' &&
            <div className="message--error mt-5">
                Wybierz conajmniej jedną opcję
            </div>
            }
        </div>
    );
}

CustomSelect.defaultProps = {
    multi: true,
    closeMenu: false,
    titleBottom: false
}
