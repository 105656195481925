import * as React from "react";
import {useRef, useState} from "react";
import {ErrorMessage, Field, Form, Formik, FormikTouched} from "formik";
import "../sass/form.scss";
import {FormValues} from "../types/formTypes";
import {CustomSelect} from "./CustomSelect";
import {
    exploitationOptionsAdvertisement,
    exploitationOptionsCorporate,
    territory,
    timeOptions
} from "../helpers/options";
import {SongInput} from "./SongInput";
import {modeEnum, viewModeEnum} from "../enum/global";
import {baseURL} from "../index";
import {
    formValidation,
    generateValues,
    mapToLinks,
    mapToSummary,
    postData,
    buyLicense,
    editLicense,
    validateEdit
} from "../helpers/formHelper";
import DatePicker from 'react-date-picker';
import {DictionaryPL, httpColors} from "../helpers/dictionary";
import Switch from "react-switch";
import moment from "moment";

let initValues: any = null;
let summaryValues: any = null;
let initCheckList: any = {
    update: false,
    hasBought: false
};

export const MainForm = React.forwardRef((props: any, ref) => {
    React.useImperativeHandle(ref, () => ({
        submitForm: () => {
            submitRef.current.click();
        }
    }));

    const [viewMode, setViewMode] = useState(viewModeEnum.DEFAULT);
    const [licenses, setLicenses] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [songIncluded, setSongIncluded] = useState(false);
    const [checkList, setCheckList] = useState(initCheckList);

    const submitRef = useRef(null);

    let initialFormTouched: FormikTouched<FormValues> = {
        videoType: false,
        projectName: false,
        email: false,
        companyName: false,
        licenseLength: false,
        mediaType: false,
        territory: false,
        startDate: false,
        songList: [
            {
                title: false,
                // album: false,
                performer: false
            }
        ]
    };

    let licensesPosted: Array<any> = [];
    let evaluationHash: string = null;
    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search);
    evaluationHash = searchParams.get('hash');

    React.useEffect(() => {
        // const url = new URL(window.location.href);
        // const searchParams = new URLSearchParams(url.search);
        // evaluationHash = searchParams.get('hash');
        initValues = generateValues();

        if (evaluationHash !== null && viewMode !== viewModeEnum.SUMMARY_VIEW) {
            // request pobierający dane zapisane dla zapytania o id evaluationHash
            fetch(`${baseURL}/${evaluationHash}`)
                .then((response) => {
                    return response.json();
                })
                .then((res) => {
                    props.changeView(viewModeEnum.SUMMARY_VIEW);
                    initValues = res;
                    summaryValues = mapToSummary(res);
                    setViewMode(viewModeEnum.SUMMARY_VIEW);
                    if (moment(res.licenses[0].sellDate).isValid()) {
                        setCheckList({...checkList, hasBought: true});
                    }
                });
        } else if (viewMode === viewModeEnum.DEFAULT) {
            // jeśli nie ma parametru (oraz inne sytuacje np. nie pobrano albo błędnie) zwracam initialObject
            props.changeView(viewModeEnum.FORM_VIEW);
            setViewMode(viewModeEnum.FORM_VIEW);
        }
    }, [viewMode]);

    const textInputFields = [
        {
            name: "projectName",
            title: "Nazwa projektu"
        },
        {
            name: "email",
            title: "email"
        },
        {
            name: "companyName",
            title: "Nazwa firmy"
        }
    ];

    // async function requestDivisor(values: any) {
    //     let initKeys = ['videoType', 'mediaType', 'territory', 'songList'];
    //     let initGroup: Array<any> = [];
    //
    //     props.changeIsLoading(true);
    //
    //     // dodanie daty do forma w odpowiednim formacie
    //     values['startDate'] = startDate !== null ? moment(startDate).format('YYYY-MM-DD HH:MM:SS') : null;
    //     // zostawienie zapytań tylko dla PL
    //     values['territory'] = ['PL'];
    //
    //     initKeys.forEach(key => {
    //         initGroup.push(values[key]);
    //     });
    //
    //     let result: Array<any> = [];
    //
    //     (function compute(group: Array<any>, combo: Array<any>) {
    //         let currentGroup = group[0];
    //
    //         if (group.length > 1) {
    //             let nextGroup = group.slice(1);
    //             currentGroup.forEach((item: any) => {
    //                 compute(nextGroup, combo.concat(item));
    //             });
    //         } else {
    //             currentGroup.forEach((item: any) => {
    //                 result.push(combo.concat(item));
    //             });
    //         }
    //     })(initGroup, []);
    //
    //     let tmpForm = values;
    //     for (let i = 0; i < result.length; i++) {
    //         initKeys.forEach((key, index) => {
    //             tmpForm[key] = result[i][index];
    //             // zamieniam na 1 stringa
    //             if (key === 'songList') {
    //                 tmpForm['trackName'] = songIncluded ? `${result[i][index].title}, ${result[i][index].performer}` : '';
    //                 tmpForm['musicDatabase'] = result[i][index].musicDatabase;
    //             }
    //         });
    //         // usunięcie nieużywanych pól
    //         delete tmpForm['songList'];
    //         delete tmpForm['videoType'];
    //
    //         // zmiana na zlą literówkę
    //         tmpForm['terytory'] = tmpForm['territory'];
    //         delete tmpForm['territory'];
    //
    //         await postData(baseURL, tmpForm).then(data => {
    //             licensesPosted.push(data);
    //         }).catch(err => {
    //             props.changeIsLoading(false);
    //             console.log(err);
    //         });
    //     }
    //     props.changeIsLoading(false);
    //     setViewMode(viewModeEnum.LIST_VIEW);
    //     setLicenses(licensesPosted);
    //     props.changeView(viewModeEnum.SUMMARY_VIEW);
    // }

    async function requestDivisorV2(values: any) {
        let tmpForm: any = {};
        let hasError: any = false;

        tmpForm["licenseData"] = {
            "email": values.email || "",
            "companyName": values.companyName || "",
            "projectName": values.projectName || "",
            "trackName": "test",
            "licenseLength": values.licenseLength || "",
            "terytory": "PL",
            "videoType": "ADVERT",
            "musicDatabase": "Right Publishing",
            "startDate": startDate !== null ? moment(startDate).format('YYYY-MM-DD HH:MM:SS') : null,
            "promoCode": values.promoCode || ""
        };
        tmpForm["mediaTypes"] = values.mediaType || [];

        props.changeIsLoading(true);
        for (let i = 0; i < values.songList.length; i++) {
            const trackName = songIncluded ? `${values.songList[i].title}, ${values.songList[i].performer}` : '';
            const musicDatabase = songIncluded ? values.songList[i].musicDatabase : '';
            tmpForm.licenseData['musicDatabase'] = musicDatabase;
            tmpForm.licenseData['trackName'] = trackName;

            await postData(baseURL + '/add', tmpForm).then(data => {
                props?.showAlertBox({
                    title: data.title || "",
                    body: data.body || "",
                    color: data.color || ""
                });
                // był problem z referencją do obiektu i do licensesPosted waliło obiekt, który się modyfikował
                // triki to obchodzące nie zadziałały więc póki co zostawiam w taki "bezpieczny" sposób
                licensesPosted.push({
                    "email": tmpForm.licenseData.email || "",
                    "companyName": tmpForm.licenseData.companyName || "",
                    "projectName": tmpForm.licenseData.projectName || "",
                    "trackName": trackName,
                    "licenseLength": tmpForm.licenseData.licenseLength || "",
                    "terytory": "PL",
                    "videoType": "ADVERT",
                    "mediaTypes": tmpForm.mediaTypes.toString() || "",
                    "musicDatabase": musicDatabase,
                    "startDate": startDate !== null ? moment(startDate).format('YYYY-MM-DD HH:MM:SS') : null,
                    "hash": data.hash
                });
            }).catch(err => {
                hasError = true;
                props?.showAlertBox({
                    title: err.title || "",
                    body: err.body || "",
                    color: err.color || "",
                    // afterClose: () => window.location.reload()
                });
            });
        }

        props.changeIsLoading(false);
        if (!hasError) {
            setViewMode(viewModeEnum.LIST_VIEW);
            props.changeView(viewModeEnum.SUMMARY_VIEW);
            setLicenses(licensesPosted);
        }
    }

    if (viewMode === viewModeEnum.LIST_VIEW && licenses.length > 0) {
        return (
            <table className='responsive'>
                {
                    <thead>
                    {
                        Object.entries(mapToLinks(licenses[0])).map((item: any) => <th>
                            {DictionaryPL[item[0]] || item[0]}
                        </th>)
                    }
                    </thead>
                }
                {
                    <tbody>
                    {
                        licenses.map((license: any) => {
                            let lic = mapToLinks(license);
                            return (
                                <tr>{Object.entries(lic).map((item: any) => {
                                        return <td
                                            data-label={DictionaryPL[item[0]] || item[0]}>{item[0] === 'linkToValuation' ?
                                            <a href={item[1]} target='_blank'
                                               rel="noopener noreferrer">Link</a> : item[1]}</td>
                                    }
                                )} </tr>)
                        })
                    }
                    </tbody>
                }
            </table>
        );
    } else if (initValues !== null && viewMode === viewModeEnum.FORM_VIEW) {
        return (
            <div className="main-form">
                <Formik
                    initialValues={initValues}
                    initialTouched={initialFormTouched}
                    validate={values => formValidation(values, songIncluded)}
                    onSubmit={(values: FormValues, {setSubmitting}) => {
                        // requestDivisor(values);
                        requestDivisorV2(values);
                    }}
                >
                    {({setFieldValue, values, errors, touched}) => {
                        values.promoCode = props.promoCode !== '' ? props.promoCode : null;
                        return (
                            <Form>
                                <div className="row flex flex--wrap info-container mt-50">
                                    {textInputFields.map((item, index) => {
                                        return (
                                            <div
                                                className={"flex flex--column text-input quarter-col--mr " + (index === 0 ? 'first-quarter' : '')}
                                                key={index}>
                                                <Field
                                                    type="text"
                                                    name={item.name}
                                                    id={`${item.name}Id`}
                                                    key={item.name}
                                                />
                                                <label
                                                    htmlFor={`${item.name}Id`}
                                                    className="text-input__label"
                                                >
                                                    {item.title}
                                                </label>
                                                <ErrorMessage
                                                    name={item.name}
                                                    component="div"
                                                    className="message--error"
                                                />
                                            </div>
                                        );
                                    })}
                                    <CustomSelect name="licenseLength" setField={setFieldValue} multi={false}
                                                  title="Czas licencji" options={timeOptions} errors={errors}
                                                  addClass="last-quarter license-length-select" touched={touched}
                                                  closeMenu={true}
                                                  titleBottom={true}/>
                                </div>

                                <div className="row flex mt-50 select-container">
                                    <CustomSelect title="Pola eksploatacji" name="mediaType" setField={setFieldValue}
                                                  options={getOptions(modeEnum.ADVERTISING_MOVIES)} errors={errors}
                                                  touched={touched}
                                                  addClass='select-exp'/>
                                    <CustomSelect title="Terytorium" name="territory" setField={setFieldValue}
                                                  options={territory} errors={errors} touched={touched}
                                                  closeMenu={true} defaultOption={territory[0]}
                                                  addClass='quarter-picker'/>
                                    <div className='quarter-picker'>
                                        <div className='quarter-picker--label'>
                                            od kiedy
                                        </div>
                                        <DatePicker value={startDate} onChange={date => setStartDate(date)}/>
                                    </div>
                                </div>

                                <div className="mt-5">
                                    <ErrorMessage
                                        name="videoType"
                                        component="div"
                                        className="message--error"
                                    />
                                </div>

                                <div className='switch-wrapper text'>
                                    <Switch onChange={() => setSongIncluded(!songIncluded)} checked={songIncluded}
                                            height={20} width={40} className='v-middle'/>
                                    <div className='ml-10 v-middle'>
                                        Chcesz podać konkretny utwór do sporządzenia zakupu czy zrobisz to później?
                                    </div>
                                    {songIncluded && <div className="row mt-50 text text--dark song-wrapper">
                                        <SongInput values={values} songErrors={errors.songList} touched={touched}
                                                   multi={true}/>
                                    </div>}
                                </div>

                                <button type="submit" className="none" ref={submitRef}>
                                    SUBMIT!
                                </button>

                                {values.territory && <div className='asterix-wrapper'>
                                    {
                                        values.territory.map((item: string) => item !== 'PL' &&
                                            <div className="text"> *Aby sporządzić wycenę licencji na terytorium innym
                                                niż Polska prosimy
                                                o kontakt mailowy office@rightpublishing.com </div>)
                                    }
                                </div>}
                            </Form>
                        )
                    }}
                </Formik>
            </div>
        );
    } else if (summaryValues !== null && viewMode === viewModeEnum.SUMMARY_VIEW) {

        return <div className='main-form'>
            <Formik initialValues={initValues}
                    validate={values => {
                        values.startDate = startDate;
                        return validateEdit(values);
                    }}
                    onSubmit={values => {
                        try {
                            const songEdit = typeof values.songList !== 'undefined' && values.songList.length > 0;
                            const dateEdit = values.licenses[0].startDate === null;

                            const editValues = Object.assign({}, values.licenses[0]);
                            if (dateEdit) {
                                editValues['startDate'] = (startDate !== null && moment(startDate).isValid()) ? moment(startDate).format('YYYY-MM-DD HH:MM:SS') : null;
                            }
                            if (songEdit) {
                                editValues['trackName'] = `${values['songList'][0].title}, ${values['songList'][0].performer}`;
                                editValues['musicDatabase'] = values['songList'][0].musicDatabase;
                                delete editValues.songList;
                            }

                            if (dateEdit || songEdit) {
                                editLicense('', editValues).then(res => {
                                    // po edycji następuje automatyczne wysłanie requesta kupującego
                                }).catch(err => {
                                    props?.showAlertBox({
                                        title: err.title || "",
                                        body: err.body || "",
                                        color: err.color || ""
                                    });
                                }).finally(() => {
                                    buyLicense(evaluationHash).then(res => {
                                        props?.showAlertBox({
                                            title: 'Zakupiono!' || "",
                                            body: 'Operacja wykonana prawidłowo',
                                            color: httpColors['201'] || "",
                                            afterClose: () => window.location.reload()
                                        });
                                        setCheckList({hasBought: true});
                                    });
                                });
                            } else {
                                buyLicense(evaluationHash).then(res => {
                                    props?.showAlertBox({
                                        title: 'Zakupiono!' || "",
                                        body: 'Operacja wykonana prawidłowo',
                                        color: httpColors['201'] || ""
                                    });
                                    setCheckList({hasBought: true});
                                });
                            }

                        } catch (e) {
                            console.log(e);
                        }
                    }} initialTouched={initialFormTouched}>
                {({setFieldValue, values, errors, touched}) => {
                    // console.log(errors);
                    const editName = <>
                        <SongInput values={values} songErrors={errors.songList} touched={touched} multi={false}/>
                    </>
                    const editDate = <>
                        <DatePicker value={startDate} onChange={date => setStartDate(date)} className='expand-100'/>
                        {errors.startDate && <div className="mt-5 message--error">
                            {errors.startDate}
                        </div>}
                    </>
                    return (
                        <Form className='edit-license'>
                            <table className='single-row'>
                                <tbody>
                                {
                                    Object.entries(summaryValues).map((item: any) => <tr key={item[0]}>
                                        <td>{DictionaryPL[item[0]] || item[0]}</td>
                                        {(item[0] === 'trackName' && !item[1]) ? <td> {editName} </td> :
                                            (item[0] === 'startDate' && !item[1]) ? <td> {editDate} </td> :
                                                <td>{DictionaryPL[item[1]] || item[1]}</td>}
                                    </tr>)
                                }
                                </tbody>
                            </table>
                            {!checkList.hasBought ?
                                <button type="submit" className="btn btn--buy"> Zakup </button> :
                                <div className='success-text'>
                                    Zakupiono
                                </div>}
                        </Form>
                    );
                }}
            </Formik>
        </div>
    } else {
        return <h1>Loading...</h1>;
    }
});

function getOptions(type: modeEnum) {
    switch (type) {
        case modeEnum.ADVERTISING_MOVIES: {
            return exploitationOptionsAdvertisement;
        }
        case modeEnum.CORPORATE_MOVIES: {
            return exploitationOptionsCorporate;
        }
        default: {
            return exploitationOptionsAdvertisement;
        }
    }
}
