import * as React from "react";
import {Field, FieldArray} from "formik";

interface IProps {
    values?: any,
    songErrors?: any,
    touched?: any,
    multi: boolean
}

export const SongInput: React.FC<IProps> = props => {
    const {values, songErrors, multi} = props;
    if (typeof values.songList === 'undefined') {
        values['songList'] = [
            {
                title: "",
                // album: "",
                performer: "",
                musicDatabase: "Right Publishing"
            }
        ]
    }

    return (
        <FieldArray name="songList">
            {arrayHelpers => (
                <>
                    {values.songList?.map((song: any, index: any) => (
                            <div className="flex text-inputs-wrapper mt-20" key={index}>
                                <div className="flex flex--column text-input half-col--mr">
                                    <Field
                                        type="text"
                                        name={`songList.${index}.title`}
                                        id="songNameId"
                                    />
                                    <label
                                        htmlFor="songNameId"
                                        className="text-input__label"
                                    >
                                        Nazwa
                                    </label>
                                    {getError(songErrors, index, 'title')}
                                </div>
                                {/*<div className="flex flex--column text-input quarter-col--mr">*/}
                                {/*    <Field*/}
                                {/*        type="text"*/}
                                {/*        name={`songList.${index}.album`}*/}
                                {/*        id="albumNameId"*/}
                                {/*    />*/}
                                {/*    <label*/}
                                {/*        htmlFor="albumNameId"*/}
                                {/*        className="text-input__label"*/}
                                {/*    >*/}
                                {/*        Album*/}
                                {/*    </label>*/}
                                {/*    {getError(songErrors, index, 'album')}*/}
                                {/*</div>*/}
                                <div className="flex flex--column text-input quarter-col">
                                    <Field
                                        type="text"
                                        name={`songList.${index}.performer`}
                                        id="performerNameId"
                                    />
                                    <label
                                        htmlFor="performerNameId"
                                        className="text-input__label"
                                    >
                                        Wykonawca
                                    </label>
                                    {getError(songErrors, index, 'performer')}
                                </div>
                                <div className="flex flex--column text-input quarter-col ml-10">
                                    <div className="row flex db-radio-wrapper">
                                        <Field
                                            type="radio"
                                            value="Right Publishing"
                                            id={`mdb-type-1-${index}`}
                                            name={`songList.${index}.musicDatabase`}
                                        />
                                        <label
                                            htmlFor={`mdb-type-1-${index}`}
                                            className="switch-radio"
                                        >
                                            Right<br/>Publishing
                                        </label>

                                        <Field
                                            type="radio"
                                            value="Right Music"
                                            id={`mdb-type-2-${index}`}
                                            name={`songList.${index}.musicDatabase`}
                                        />
                                        <label
                                            htmlFor={`mdb-type-2-${index}`}
                                            className="switch-radio"
                                        >
                                            Right<br/>Music
                                        </label>
                                    </div>
                                    <div className="text-input__label text-center">
                                        Katalog**
                                    </div>
                                </div>
                                {values.songList.length > 1 &&
                                <div className="pointer" onClick={() => arrayHelpers.remove(index)}>
                                    &#10060;
                                </div>
                                }
                            </div>
                        )
                    )}

                    {multi && <div className="add-song mt-20"
                                   onClick={() => {
                                       arrayHelpers.push({
                                           title: '', /* album: '',*/
                                           performer: '',
                                           musicDatabase: 'Right Publishing'
                                       });
                                       props.touched.songList.push({title: false, /*album: false,*/ performer: false});
                                   }}>
                        &#43; Dodaj kolejny utwór
                    </div>}
                </>
            )}
        </FieldArray>
    );

    function getError(errors: any, index: number, title: string) {
        if (props.touched.songList[index][title] && typeof errors !== 'undefined' && typeof errors[index] !== 'undefined') {
            return (
                <div className="message--error">
                    {errors[index][title]}
                </div>
            );
        }
    }
}
