import {SelectOption} from "../types/formTypes";

export const exploitationOptionsAdvertisement: Array<SelectOption> = [
    {
        value: "tv",
        label: "TV",
        color: "#00B8D9"
    },
    {
        value: "tv billboard sponsorski",
        label: "TV billboard sponsorski",
        color: "#0052CC"
    },
    {
        value: "tv billboard sponsorski (przed i po)",
        label: "TV billboard sponsorski (przed i po)",
        color: "#5243AA"
    },
    {
        value: "internet",
        label: "Internet",
        color: "#00B8D9"
    },
    {
        value: "kino",
        label: "Kino",
        color: "#0052CC"
    },
    {
        value: "radio ogólnopolskie",
        label: "Radio ogólnopolskie",
        color: "#5243AA"
    },
    {
        value: "radio regionalne",
        label: "Radio regionalne",
        color: "#00B8D9"
    },
    {
        value: "www klienta",
        label: "www klienta",
        color: "#0052CC"
    },
    {
        value: "punkty sprzedaży - POS",
        label: "Punkty sprzedaży - POS",
        color: "#5243AA"
    },
    {
        value: "OOH - zewnętrzne punkty",
        label: "OOH - zewnętrzne punkty",
        color: "#00B8D9"
    },
    {
        value: "prezentacje wew. i zew.",
        label: "Prezentacje wew. i zew.",
        color: "#0052CC"
    },
    {
        value: "eventy (konferencje)",
        label: "Eventy (konferencje)",
        color: "#5243AA"
    },
    {
        value: "making off",
        label: "Making off",
        color: "#00B8D9"
    },
    {
        value: "reklama vod",
        label: "Reklama VOD",
        color: "#0052CC"
    },
    {
        value: "reklama social media (facebook, youtube)",
        label: "Reklama social media (facebook, youtube)",
        color: "#5243AA"
    },
];

export const exploitationOptionsCorporate: Array<SelectOption> = [
    {
        value: "sklepy",
        label: "Sklepy",
        color: "#00B8D9"
    },
    {
        value: "biura",
        label: "Biura",
        color: "#0052CC"
    }
];

export const territory: Array<SelectOption> = [
    {
        value: "PL",
        label: "Polska",
        color: "#00B8D9"
    },
    {
        value: "REST",
        label: "pozostałe kraje*",
        color: "#00B8D9"
    },
    // {
    //     value: "DE",
    //     label: "Niemcy",
    //     color: "#0052CC"
    // },
    // {
    //     value: "US",
    //     label: "USA",
    //     color: "#5243AA"
    // }
];

export const timeOptions: Array<SelectOption> = [
    {
        value: "1",
        label: "1 miesiąc",
        color: "#00B8D9"
    },
    {
        value: "3",
        label: "3 miesiące",
        color: "#0052CC"
    },
    {
        value: "6",
        label: "6 miesięcy",
        color: "#5243AA"
    },
    {
        value: "12",
        label: "12 miesięcy",
        color: "#0052CC"
    }
];

